import './App.css';
import Bridge from './components/Bridge';


function App() {
  return (
    <>
      <Bridge />
    </>
  );
}

export default App;
