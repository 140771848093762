import React, { useEffect } from "react";
import InkzLogo from "../images/inkz.gif";
import InkzLogoL2 from "../images/inkz-L2.gif";
import RightArrow from "../images/right-arrow.png";
import { useState } from "react";
import "../Bridge.css";
import {
  bridgeInkz,
  bridgeContract,
  inkzL2Contract,
  connectWallet,
} from "../components/Interact";
import { Typography } from "@mui/material";
import Spinner from "./Spinner";
import BridgeImg from "../images/Bridge-FULLV1.gif";
import Web3 from "web3";
import Navbar from "./Navbar.js"
import Footer from "./Footer.js"

const Bridge = () => {
  const [amount, setAmount] = useState("");
  const [submitBtn, setSubmitBtn] = useState("Bridge");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bridgeStatus, setBridgeStatus] = useState("");
  const [wallet, setWallet] = useState("");
  const [networkID, setNetworkID] = useState("");
  console.log(networkID);

  useEffect(() => {
    function addWalletListener() {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (accounts) => {
          if (accounts.length > 0) {
            setWallet(accounts[0]);
          } else {
            setWallet("");
          }
        });
      } else {
        setStatus("no wallet connected @ bridge");
      }
    }
    addWalletListener();
  }, [wallet]);

  useEffect(() => {
    connectWalletPressed();
  }, [wallet]);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setWallet(Web3.utils.toChecksumAddress(walletResponse.address));
    setNetworkID(walletResponse.chain);
  };

  useEffect(() => {
    function updateInkzL1Balance() {
      if (wallet) {
        bridgeContract.events
          .depositInkz({})
          .on("connected", function (subscriptionId) {
            console.log("Inkz L1 listener sub ID: " + subscriptionId);
          })
          .on("data", function (event) {
            if (event.returnValues[0] === wallet) {
              setBridgeStatus("Deposit Found! Attempting Bridge");
            } else {
              //console.log("not your deposit")
            }
          });
      } else {
        console.log("wallet address not set!");
      }
    }
    updateInkzL1Balance();
  }, [wallet]);

  useEffect(() => {
    function updateInkzL2Balance() {
      if (wallet) {
        inkzL2Contract.events
          .Transfer({})
          .on("connected", function (subscriptionId) {
            console.log("Inkz L2 listener sub ID: " + subscriptionId);
          })
          .on("data", function (event) {
            if (event.returnValues[1] === wallet) {
              setBridgeStatus("Bridge Complete!");
              setIsLoading(false);
            } else {
              //console.log("not your deposit")
            }
          });
      } else {
        console.log("wallet address not set");
      }
    }
    updateInkzL2Balance();
  }, [wallet]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setBridgeStatus("Awaiting Transaction...");
    setStatus("");
    setError("");
    const status = await bridgeInkz((amount * 1000000000000000000).toString());
    setSubmitBtn(status.btnstatus);
    setStatus(status.status);
    setError(status.error);
    setIsLoading(false);
    setBridgeStatus("");
    if (!status.error) {
      handleBridge();
    }
  };

  const handleBridge = async () => {
    setIsLoading(true);
    setBridgeStatus("Awaiting Deposit...");
  };

  return (
    <div>
      <Navbar />
      <img className="cover-image mt-60p" src={BridgeImg} alt="Bridgeimg" />
      <div className="bridge-box">
          <h1 className="green-text center">BRIDGE</h1>
          <h1 className="green-text center">INKz to PINKz</h1>
          <div className="text">
            <div className="flex-item">
              <img className="mx-4 inkz-image-m" src={InkzLogo} alt="inkz" />
              <img
                className="size-arrow mx-4"
                src={RightArrow}
                alt="right arrow"
              />
              <img className="mx-4 inkz-image-m" src={InkzLogoL2} alt="inkz" />
            </div>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  className="input-bridge-field"
                  placeholder="ENTER AMOUNT"
                  type="number"
                  min="30"
                  max="10000"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="input-btn-bridge"
                  type="submit"
                  value={submitBtn}
                />
              </div>
              <h4 className="green-text">{bridgeStatus}</h4>
              {isLoading ? <Spinner /> : null}
              <Typography variant="h7" color="#4caf50">
                {status}
              </Typography>
              <Typography variant="h7" color="red">
                {error}
              </Typography>
            </form>
          </div>
      </div>
      <div className="p-10p">
        <div className="box-small center">
          <h3 className="green-text">* Minimum bridge amount *</h3>
          <h3 className="green-text">* * * 30 INKz * * *</h3>
          <p className="green-text">
            Make sure you are connected to mainnet "ETH" before continuing.
          </p>
          <p className="green-text">
            Bridging may take up to 15 minutes depending on network conditions.
          </p>
        </div>
        <div className="box-small center">
          <p className="green-text">
            Bridging to Polygon is a one way process at the moment.
          </p>
          <p className="green-text">
            If you need help, please contact a mod in our discord. They will be
            happy to help.
          </p>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Bridge;
